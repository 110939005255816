
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/ad.html",
      function () {
        return require("private-next-pages/ad.html.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/ad.html"])
      });
    }
  