const ogResolution = '/dynamic/1200x840/';
const twitterResolution = '/dynamic/1000x1000/';
const resolutionToReplace = '/dynamic/1600w/';

export const imageDataToMetaTags = (imageData) => {
    if (imageData?.src) {
        return {
            og: {
                url: imageData.src.replace(resolutionToReplace, ogResolution),
                description: imageData.description,
            },
            twitter: {
                url: imageData.src.replace(resolutionToReplace, twitterResolution),
            },
        };
    }
    return null;
};
